@media (max-width: 1199.98px) {
    
}

@media (max-width: 991.98px) {

}

@media (max-width: 767.98px) {
    .footer-body {
        flex-direction: column;
    }
    .footer-list li a {margin-left: 0;}
}

@media (max-width: 500px) {
    .avatar-actions {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .setting__user-img {
        min-width: 60px;
    }
    .plan-billing-block, .plan-billing-actions {
        flex-direction: column;
        align-items: self-start !important;
    }
    .settings-password-fields .row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .settings-password-fields .col-6 {
        width: 100%;
    }
    .swiper.thumbs-render-swiper {
        display: none;
    }
}

@media (min-width: 500px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
