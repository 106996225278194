@config "../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

.dots-menu {
    cursor: pointer;
}

.dots-menu svg {
    pointer-events: none;
}

.dots-menu-dropdown {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 5px;
    bottom: -15px;
    cursor: pointer;
    background: #28282e;
    padding: 10px 15px;
    border-radius: 5px;
    transition: 0.15s all ease-in-out;
}

.dots-menu.active + .dots-menu-dropdown {
    opacity: 1;
    pointer-events: all;
}

.dots-menu-dropdown:hover {
    color: #9EE22E;
}
.dots-menu.active svg path {
    stroke: #9EE22E;
}