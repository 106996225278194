.dropdown {
    max-width: max-content;
    margin: 0 auto;
}

.dropdown__body {
    display: none;
    position: fixed;
    background-color: white;
    box-shadow: 0px 11px 20px rgba(0, 0, 0, .2);
    overflow: auto;
    margin-top: 15px;
    z-index: 9999;
}

.dropdown__body.is-open {
    display: block;
    white-space: nowrap;
}

.footer {
    background-color: #101014;
    color: #D3D3D3;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* padding: 15px 0; */
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-top: 1px solid rgba(255, 255, 255, .03);
}

.container-1100 {
    max-width: 1100px;
}

.footer-top {
    padding: 20px 0 10px;
}
.render-results-slider.hide-tumbs-slider .swiper-thumbs{
    display: none;
}
.footer-top-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.footer-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.footer-body p,
.footer-list li a:hover {
    opacity: .3;
}

.footer-list {
    display: flex;
    align-items: center;
    color: white;
}

.footer-list li a {
    display: block;
    padding: 15px;
    margin-left: 6px;
    color: #D3D3D3;
}

.section {
    padding: 60px 0;
}

.latest-section {
    padding-bottom: 120px;
}

.section-title {
    text-align: center;
}

.section-sub-title {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    color: #D3D3D3;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.section-title+.section-sub-title {
    margin-top: 24px;
}

.btn-shapes {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}

.btn-shapes .shadow-efect-1,
.btn-shapes .shadow-efect-2,
.border-efects {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: -1;
}

.border-efects {
    width: 166px;
    height: 44px;

}

.border-efects span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 540px;
    border: 0.5px solid #FFF;
    opacity: 0.12;
    animation: borderEfects 3s linear var(--i) infinite;
}

.border-efects.w-212 {
    width: 212px;
    z-index: 1;
}

.border-efects.w-212 span {
    animation: borderEfects2 3s linear var(--i) infinite;
}

@keyframes borderEfects {
    100% {
        width: 226px;
        height: 104px;
        opacity: 0;
    }
}

@keyframes borderEfects2 {
    100% {
        width: 270px;
        height: 104px;
        opacity: 0;
    }
}

.shadow-efect-1 {
    width: 280px;
    height: 186px;
    border-radius: 280px;
    opacity: 0.4;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #9EE22E 0%, #101014 100%);
    filter: blur(50px);
}

.shadow-efect-2 {
    border-radius: 678px;
    opacity: 0.6;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #9EE22E 0%, #101014 100%);
    filter: blur(350px);
    width: 678px;
    height: 338px;
}

.swiper-right-dir,
.swiper-left-dir {
    overflow: visible !important;
    pointer-events: none;

}

.swiper-right-dir {
    margin-bottom: 20px;
}

.swiper-right-dir .swiper-wrapper,
.swiper-left-dir .swiper-wrapper {
    transition-timing-function: linear;
}

.swiper-right-dir .swiper-slide,
.swiper-left-dir .swiper-slide {
    height: 190px;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.latest-sliders-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 7px;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    max-width: max-content;
}

.features-item {
    overflow: hidden;
    border-radius: 16px;
    border-top: 1px solid rgba(158, 226, 46, 0.14);
    background: #14141A;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 30px;
}

.features-item p {
    color: rgba(211, 211, 211, .7);
}

.features-item__icon {
    width: 70px;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 360px;
    background: linear-gradient(180deg, #131318 0%, #1A1A22 100%);
    margin-bottom: 35px;
}

.features-item__shape {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 360px;
    border: 0.5px solid rgba(158, 226, 46, 1);
    opacity: .14;
    animation: sixSahpeAnim 6s linear var(--i) infinite;
}

@keyframes sixSahpeAnim {
    100% {
        width: 440px;
        height: 440px;
        opacity: 0;
    }
}

.features-item__text {
    margin-top: auto;
}

.g-24 {
    --bs-gutter-x: 24px;
    --bs-gutter-y: 24px;
}



.slider-images {
    position: relative;
    width: 100%;
    height: 370px;
    /* border: 2px solid white; */
    border-radius: 16px;
    overflow: hidden;
}

.slider-images .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 700px 100%;
    background-repeat: no-repeat;
    /* border-radius: 16px; */
}

/* .slider-images .background-img:after,
.slider-images .foreground-img:after {
    color: rgb(255, 255, 255, .8);
    display:inline-flex;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    padding: 5px 10px;
    position: absolute;
    top: 16px;
}
.slider-images .background-img:after  {
    content: 'After';
    right: 16px;
}
.slider-images .foreground-img:after  {
    content: "Before";
    left: 16px;
} */


.slider-images .background-img .after-badge,
.slider-images .foreground-img .before-badge {
    color: rgb(255, 255, 255, .8);
    display: inline-flex;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    padding: 5px 10px;
    position: absolute;
    top: 16px;
}

.slider-images .background-img .after-badge {
    /* content: 'After'; */
    right: 16px;
}

.slider-images .foreground-img .before-badge {
    /* content: "Before"; */
    left: 16px;
}

.slider-images .foreground-img {
    width: 50%;
    overflow: hidden;
}

.slider-images .slider {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    margin: 0;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-images .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2px;
    height: 370px;
    background: transparent;
    cursor: pointer;
}

.slider-images .slider::-moz-range-thumb {
    width: 2px;
    height: 370px;
    cursor: pointer;
    background: transparent;
}

.slider-images .slider-button {
    --size: 44px;
    width: var(--size);
    height: var(--size);
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    /* margin-left: -1px; */
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #9EE22E;
    background-image: url(../img/left-right-arrow.svg);
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
}

.slider-images .slider-line {
    width: 2px;
    height: 100%;
    pointer-events: none;
    position: absolute;
    /* margin-left: -1px; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.43;
    background: #D9D9D9;
}

.gx-70 {
    --bs-gutter-x: 70px;
}

.gy-30 {
    --bs-gutter-y: 30px;
}

.slider-images-shapes {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100%;
    left: -5px;
}

.slider-images-shapes.--right-shapes {
    right: -5px;
    left: auto;
}

.slider-images-shapes>* {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 360px;
    border: 0.5px solid rgba(255, 255, 255, 1);
    opacity: 0;
    animation: fiveSahpeAnim 5s linear var(--i) infinite;
}

@keyframes fiveSahpeAnim {
    0% {
        opacity: .12;
    }

    100% {
        width: 440px;
        height: 440px;
        opacity: 0;
    }
}

.section-text {
    color: rgba(211, 211, 211, .7);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.getting-section {
    padding: 120px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* background-blend-mode: normal, luminosity; */
}

/* .getting-section:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0 ;
    top: 0;
    background: linear-gradient(360deg, #101014 0%, rgba(16, 16, 20, 0.00) 100%);
    
} */

.abount-image {
    border-radius: 16px;
    overflow: hidden;
    display: flex;
}

.abount-image>* {
    width: 100%;
}

.container-full {
    max-width: none;
}

.color-secondary-07 {
    color: rgba(211, 211, 211, .7);
}

.section-scroll-container {
    border-radius: 16px;
    padding: 16px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.empty-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.empty-page .features-item__icon {
    margin-bottom: 64px;
}

.empty-page h4 {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.fullpage-title {
    color: rgba(255, 255, 255, .6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
}

.fullpage-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -8px;
    margin-top: 8px;
}

.fullpage-actions>* {
    margin-top: 8px;
    margin-right: 8px;
}

.fullpage-slider-content {
    flex: auto;
    display: flex;
    align-items: center;
}

.full-page-section__content {
    overflow: hidden;
}

.render-results-slider {
    /* max-width: 755px; */
    margin-left: auto;
    margin-right: auto;
}

.modal .render-results-slider {
    max-width: initial;
}

.render-results-slider .swiper-slide {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
}

.render-results-slider .swiper-slide img {
    width: 100%;
}

.thumbs-render-swiper .swiper-slide {
    border: 1px solid transparent;
}

.thumbs-render-swiper .swiper-slide-thumb-active {
    border: 1px solid #9EE22E;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    text-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
    font-size: 24px;
    opacity: 0.6;
    transition: 0.3s all ease-in-out;
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
    opacity: 1;
}

.pos-absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.slider-contetn-block {
    padding: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider-top-content-block {
    position: absolute;
    top: 12px;
    left: 12px;
}

.slider-bottom-content-block {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
}

.slider-label {
    display: inline-block;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    color: rgba(255, 255, 255, .8);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 7px;
}

.render-results-slider {
    --swiper-navigation-color: #fff;
    width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    height: 100%;
    margin-top: 0;
    top: 0;
    width: 80px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 0;
    justify-content: start;
    padding-left: 16px;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 0;
    padding-right: 16px;
    justify-content: flex-end;
}

.section-scroll-heading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -16px;
}

.section-scroll-heading>* {
    margin-bottom: 16px;
    margin-right: 16px;
}

.latest-render-exzample,
.render-exzample-item {
    border-radius: 16px;
    max-height: 400px;
    display: flex;
    background-color: lightgray;
    position: relative;
    overflow: hidden;
}

.render-exzample-item {
    min-height: 225px;
    cursor: pointer;
}

.latest-render-exzample img,
.render-exzample-item img {
    width: 100%;
}

.render-exzample-item {
    position: relative;
    padding-bottom: 100%;
    min-height: initial;
    max-height: initial;
}

.render-exzample-item img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay-content-block {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.rendering-label {
    align-self: center;
    margin-top: auto;
    text-align: center;
    padding: 8px 14px;
    border-radius: 12px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.rendering-label>*:last-child {
    color: rgba(255, 255, 255, .6);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.latest-render-label,
.latest-render-name {
    display: inline-flex;
    color: rgba(255, 255, 255, .8);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 7px;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
}

.latest-render-name {
    margin-top: auto;
    align-self: center;
}

.g-20 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
}


.rendering-animation-block {
    color: #D3D3D3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rendering-animation-block__image {
    width: 240px;
    height: 170px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 70px;
}

.rendering-animation-block__image {
    width: 240px;
    height: 170px;
    border-radius: 10px;
}

.rendering-animation-block__title {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
}

.rendering-animation-block__shape {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 170px;
    position: absolute;
    border-radius: 16px;
    border: 0.5px solid #FFF;
    opacity: 0.1;
    pointer-events: none;
    animation: renderingAnimation 3s linear var(--i) infinite;
}

@keyframes renderingAnimation {
    100% {
        width: 330px;
        height: 262px;
        opacity: 0;
    }
}

.input-custom__input {
    outline: 1px solid transparent;
}

.input-custom__input.error {
    outline: 1px solid rgb(151 43 43);
}

.modal__back-sub {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Upgrade to pro popup */
.modal__dialog--897 {
    max-width: 897px;
}

.upg-to-pro__wrapper {
    display: flex;
}

.up-to-pro__block {
    padding: 30px 36px;
}

.up-to-pro__block.second {
    background: #101014;
    border-radius: 0 16px 16px 0;
}

.btn.up-to-pro__plan-button {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 10px 14px;
    align-items: center;

    border-radius: 10px;
    background: #28282E;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.btn.up-to-pro__plan-button.active {
    color: #9EE22E;
    border-radius: 10px;
    background: #262E19;
}

.btn.up-to-pro__plan-button.service-pay {
    align-items: start;
    justify-content: start;
    flex-direction: column;
    height: auto;
}

.fake-radio {
    position: relative;
    display: flex;
    width: 22px;
    height: 22px;
    padding: 5px;
    border-radius: 300px;
    border: 1.5px solid #FFF;

    opacity: 0.5;
    margin-right: 10px;
    margin-top: 1px;
}

input[type="radio"]:checked+.fake-radio-payment .fake-radio {
    background: #9EE22E;
    border: unset;


}

.fake-radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: black;
}

.up-to-pro__payment-block {
    display: flex;
    height: 40px;
    padding: 10px 0px;
    align-items: center;
}

.up-to-pro__payment-cards {
    display: flex;
    align-items: center;
}

.up-to-pro__payment-cards.right-side {
    position: absolute;
    top: 30%;
    right: 14px;
}


.up-to-pro__card {
    margin-left: 6px;
    display: flex;
    width: 22.667px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 2.667px;
    background: #FFF;
}

.up-to-pro__card.no-bg {
    background: unset;
}

.fake-radio-payment {
    width: 100%;
    display: flex;
    cursor: pointer;
}

.up-to-pro__exit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 360px;
    background: #28282E;
}

.up-to-pro__divider {
    height: 1px;
    opacity: 0.06;
    background: #FFF;
}

.up-to-pro__footer-text {
    padding: 0 20px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.4;
}

.billed-to::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.faq-upscale {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.faq-upscale.active {
    height: 50px;
}

.main-render-swiper .slider-images {
    height: 100%;
}

.main-render-swiper .slider-images .background-img:after,
.main-render-swiper .slider-images .foreground-img:after {
    font-size: 12px;
    line-height: 18px;
    padding: 0 7px;
}

.main-render-swiper .slider-images .slider-line {
    background: #101014;
    opacity: 1;
}

.slider-images .background-img.after-video-text .after-badge {
    right: auto;
    left: 16px;
}

.toggle-checkbox-button {
    width: 50px;
    height: 28px;
    padding: 3px;
    border-radius: 360px;
    background: #28282E;
    position: relative;
    display: block;
}

.toggle-checkbox-button:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #101014;
    position: absolute;
    left: 3px;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.7s all ease-in-out;
}

.toggle-checkbox-button.active {
    background: #ACE64B;
}

.toggle-checkbox-button.active:before {
    right: 3px;
    left: auto;
}