.page-menu-list {
    display: flex;
    align-items: center;
    padding:0 27px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(16, 16, 20, 0.98);
    backdrop-filter: blur(30px);
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}
@supports (backdrop-filter: blur(30px)) {
    .page-menu-list {
        background: rgba(16, 16, 20, 0.60);
        backdrop-filter: blur(30px);
    }
}

.widthout-image-text.slider-images .foreground-img:after,
.widthout-image-text.slider-images .background-img:after {
    display: none;
}
.page-menu-list .btn {
    border: 0;
    min-width: 124px;
    border-bottom: 1px solid transparent;
}
.page-menu-list .btn.active {
    border-color:var(--primary);
    color: var(--primary);
}

.page-menu-list .btn:hover {
    color: var(--primary);
}

.pricing__img-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background: linear-gradient(0deg, #9EE22E, #9EE22E),
radial-gradient(47.5% 47.5% at 29.17% 25.83%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    margin-right: 14px;
}

.slider-actions-block {
    position: absolute;
    gap: 8px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 20px;
}

.range-slider-group {
    display: flex;
    align-items: center;
    gap: 12px;
}

.range-slider-group__value {
    border-radius: 8px ;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    min-width: 32px;
    height: 22px;
    text-align: center;
    background: rgba(40, 40, 46, 1);
}
.noUi-connect {
    background: var(--primary);
}
.noUi-target {
    background: rgba(40, 40, 46, 1);
    box-shadow: none;
    border: 0;
    width: 100%;
}
.noUi-horizontal {
    height: 8px;
}

.noUi-horizontal .noUi-handle {
    width: 22px;
    height: 22px;
    right: -11px;
}

.noUi-handle {
    border-radius: 50%;
    background: linear-gradient(180deg, #B7B7B7 0%, #FFFFFF 100%);
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: none;
}

.noUi-handle:after, .noUi-handle:before {
    display: none;
}
.g-16 {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 16px;
}

.slider-images.slider-images--4-3 {
    height: 100%;
    aspect-ratio: 4/3;
}
.slider-images.slider-images--4-3 .img {
    background-size: 775px 100%;
}
.btn.btn--render-js-1,
.btn.btn--render-js-2 {
    flex-direction: row;
    gap: 0;
}
@media (max-width: 992px) {
    .aside-footer .btn--render-js-1 {
        /* display: none; */
    }
    .aside--controls-3 {
        display: none;
    }
    .full-page-section .aside--controls-1 {
        z-index: 100;
    }
}