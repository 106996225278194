.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;
}
.switch__slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: radial-gradient(56.63% 56.63% at 29.17% 25.83%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #9EE22E;
    border: 1px solid rgba(255, 255, 255, 0.12);
	border-radius: 360px;
	transition: .4s;
}
.switch__slider::before {
	content: "";
	position: absolute;
	height: 22px;
	width: 22px;
	left: 3px;
	bottom: 2px;
	border-radius: 50%;
	background-color: #101014;
	transition: .4s;
}
.switch__field {
	width: 0;
	height: 0;
	opacity: 0;
	visibility: hidden;
}

.switch__field:checked + .switch__slider::before {
	transform: translateX(20px);
    right: 3px;
}

/* ============checkbox============= */

.custom-checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.custom-checkbox__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: none;
}
.custom-checkbox__input-fake {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #28282E;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.custom-checkbox__input:checked ~ .custom-checkbox__input-fake {
    background-image: url(../img/icons/icon-done-check.svg);
}

.custom-checkbox__label {
    font-size: 14px;
    line-height: 20px;  
    padding-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
}

/* ===============/checkbox=============== */

/* ===============radio================= */

.custom-radio__input{
    display: none;
}

.custom-radio__input-fake{
    padding: 10px 14px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    border-radius: 10px;
    background-color: #28282E;
    height: 44px;
    width: 100%;
    cursor: pointer;
    display: block;
    text-align: center;
}

.custom-radio__input:checked ~ .custom-radio__input-fake {
    color: #9EE22E;
    background-color: #262E19;
}

/* ===============/radio================= */

/* ===============select================= */
.select { 
    display: block; 
    font-size: 14px; 
    font-family: 'SF Pro Text', sans-serif; 
    font-weight: 400; 
    color: #fff; 
    line-height: 22px; 
    padding: 10px 44px 10px 14px; 
    width: 100%; 
    max-width: 100%; 
    box-sizing: border-box; 
    margin: 0; 
    border-radius: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #28282E;
    background-image: url('../img/icons/icon-select-arrow.svg'); 
    background-repeat: no-repeat, repeat;
    background-position: right 10px top 50%, 0 0;
    background-size: 28px auto, 100%; 
}

.select::-ms-expand { 
    display: none; 
}

.select:focus { 
    color: #fff;
    outline: none; 
}

.select option { 
    font-weight:normal;
}

/* ===============/select================= */

.select-decor{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: #28282E;
    height: 120px;
}

.select-decor__type{
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    line-height: 18px;
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    padding: 2px 7px;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    width: max-content;
    justify-content: center;
    max-width: calc(100% - 10px);
}

.select-decor .dropdown-arrow {
    background-image: url('../img/icons/icon-select-arrow.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 26px auto, 100%;
    width: 22px;
    height: 22px;
    margin-left: 5px;
    display: block;
}

.upload-wrap__input{
    display: none;
}

.upload-wrap{
    padding: 24px 10px;
    border: 1px dashed rgba(255, 255, 255, 0.12);
    background-color: #101014;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    position: relative;
}
.upload-wrap--dragged{
    /* background-color: #1010140a; */
    background-color: #28282E;
}
.upload-wrap__label{
    display: block;
    color: #9EE22E;
    font-size: 14px;
    line-height: 20px;
}

.upload-wrap__label-text{
    display: block;
    color: #D3D3D3;
    font-size: 12px;
    line-height: 20px;
}

/* =============faq============== */
.faq__item{
    border-radius: 16px;
    background-color: #14141A;
    padding: 16px 16px 16px 20px;
}

.faq__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faq__title{
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 24px;
}

.faq__content{
    color: #D3D3D3;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 6px;
}

.faq__item.active .faq__arrow{
    transform: rotate(180deg);
}

.faq__item.active .faq__title{
    color: #9EE22E;
}

.faq__arrow{
    display: flex;
}

.faq__item:not(:last-child){
    margin-bottom: 8px;
}

.faq-wrapper{
    max-width: 800px;
    margin: 0 auto;
}
/* =============/faq============== */

/* ============pricing============= */

.pricing{
    max-width: 954px;
    margin: 0 auto;
}

.pricing__card{
    padding: 20px;
    border-radius: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(20, 20, 26, 0.60);
    backdrop-filter: blur(30px);
    position: relative;
}

.pricing__img-box{
    border-radius: 360px;
    background: linear-gradient(180deg, #131318 0%, #1A1A22 100%);
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
}

.pricing__item-group-text{
    display: flex;
    flex-direction: column;
    line-height: 18px;
}

.pricing__item{
    display: flex;
    align-items: center;
}

.pricing__list li:not(:last-child){
    margin-bottom: 16px;
}

.pricing__list{
    margin-bottom: 20px;
}

.bg-decor{
    position: relative;
}

.bg-decor::after{
    content: '';
    position: absolute;
    border-radius: 678px;
    opacity: 0.6;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #9EE22E 0%, #101014 100%);
    filter: blur(350px);
    width: 678px;
    height: 338px;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: -1;
}

.switch-decor{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch-decor::after{
    content: '';
    position: absolute;
    width: 250px;
    height: 68px;
    opacity: 0.4;
    filter: blur(50px);
    background: radial-gradient(50% 50.00% at 50% 50.00%, #9EE22E 0%, #101014 100%);;
    pointer-events: none;
    z-index: -1;
}

.pricing__popular{
    position: absolute;
    right: 12px;
    top: 12px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    padding: 2px 6px;
    border-radius: 300px;
    background-color: #5E2BFF;
    display: block;
}
/* ============/pricing============= */

/* =========Profile Settings========= */

.settings{
    height: 100%;
}

.setting__user-img{
    width: 60px;
    height: 60px;
    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.36);
    overflow: hidden;
}

.plan-billing-card{
    padding: 13px 16px;
    border-radius: 14px;
    background: #28282E;
    position: relative;
}

.change-image{
    cursor: pointer;
}

.plan-billing-list{
    margin-left: -6px;
    margin-right: -6px;
}

.plan-billing-item{
    padding-left: 6px;
    padding-right: 6px;
    flex: 0 0 180px;
    margin-bottom: 24px;
}

.plan-billing-list{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.table-wrapper{
    overflow: auto;
}

.table{
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
}
/* =========/Profile Settings========= */

/* ==============table================ */
.table thead {
    border-radius: 8px;
    background-color: #28282E;
}

.table thead tr th {
    color: rgba(211, 211, 211, 0.7);
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    text-align: left;
}

.table tbody tr td {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 10px;
    text-align: left;
}

/* ==============/table================ */

.checkbox-decor{
    width: 28px;
    height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00B700;
    border-radius: 300px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.closed-decor{
    width: 28px;
    height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ED0000;
    border-radius: 300px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.photo-example{
    position: relative;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-box-36x36{
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.36);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.qr-code-wrap{
    padding-left: 40px;
    margin-left: 40px;
    border-left: 1px solid rgba(255, 255, 255, 0.06);
}

.plan-billing-card.active{
    background-color: #262E19;
    color: #9EE22E;
}

.input-label{
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
}

.input-custom__wrap-input{
    position: relative;
}

.input-custom__input{
    padding: 11px 14px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    border-radius: 10px;
    background-color: #28282E;
    width: 100%;
}

.input-custom__marker-right{
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
}

.icon{
    display: flex;
}

.btn--gradient-blue,
.btn.btn--gradient-blue {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: radial-gradient(56.63% 56.63% at 29.17% 25.83%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #5E2BFF;
    color: #fff;
}

.btn--gradient-blue:hover,
.btn.btn--gradient-blue:hover {
    border: 1px solid #5E2BFF;
    background: #101014;
    opacity: 1;
}

.btn--outline-gradient-blue,
.btn.btn--outline-gradient-blue {
    border: 1px solid #5E2BFF;
    background: #101014;
    opacity: 1;
}

.btn--outline-gradient-blue:hover,
.btn.btn--outline-gradient-blue:hover {
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: radial-gradient(56.63% 56.63% at 29.17% 25.83%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #5E2BFF;
    color: #fff;
}

.btn.btn--gradient-green,
.btn--gradient-green {
    border: 1px solid rgba(255, 255, 255, 0.36);
    background: radial-gradient(56.63% 56.63% at 29.17% 25.83%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), #9EE22E;
    color: #101014;
}

.btn--secondary-2,
.btn.btn--secondary-2 {
    background-color: #28282E;
    color: #fff;
}

.btn--md,
.btn.btn--md {
    padding: 6px 13px;
}

.btn.font-\[600\] {
    font-weight: 600;
}

.btn.text-\[18px\] {
    font-size: 18px;
}

.btn.text-\[15px\] {
    font-size: 15px;
}

.btn--lg-2,
.btn.btn--lg-2 {
    padding: 10px 19px;
}

.btn--lg-3,
.btn.btn--lg-3 {
    padding: 8px 19px;
}

.gx-7{
    --bs-gutter-x: 7px
}

.gx-14{
    --bs-gutter-x: 14px
}

.gx-16{
    --bs-gutter-x: 16px
}

.g-24{
    --bs-gutter-x: 24px;
    --bs-gutter-y: 24px;
}

.border-bottom{
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}