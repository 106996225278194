.scroll_to_top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    background: #F8F8F8 url(../img/to_top.svg) no-repeat center;
    background-size: 70%;
    transition: all 0.15s ease-in-out;
    z-index: 10;
}
.aside-footer{
    padding-top: 16px;
    padding-bottom: 16px;
    background: rgba(23, 22, 29, 0.60);
    backdrop-filter: blur(20px);
}
.ico{display:flex;align-items:center;}
.ico-20{
    min-width: 20px;
    max-width: 20px;
    height: 20px;
}
.ico-28{
    min-width: 28px;
    max-width: 28px;
    height: 28px;
}
.scroll_to_top.active {
    opacity: 0.6;
    visibility: visible;
}

.scroll_to_top.active:hover {
    opacity: 1;
}

.aside{
    background-color: #14141A;
    border-radius: 16px;
    overflow: hidden;
}
.aside-body{
    height: 100%;
    overflow-y: scroll;
    padding: 0;
    overflow-x: hidden;
}
.aside,
.aside-container{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.form-group{
    display: flex;
    flex-direction: column;
}
.form-group--header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form-group--header > *{
    margin-bottom: 6px;
}
.form-group--header > *:not(:last-child){
    margin-right: 10px;
}
.aside--controls-1{
    width: 395px;
    flex: 0 0 395px;
    transition: all 0.4s;
}
.aside--controls-2{
    width: 282px;
    flex: 0 0 282px;
    transition: all 0.4s;
}
.full-page-section__content{
    flex: auto;
    border-radius: 16px;
    background: #14141A;
}
.empty-page{
    overflow-x: hidden;
}
.full-page-section{
    height: calc(100vh - var(--header-fixed-padding));
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;

    margin-left: -16px;
    margin-right: -16px;
    padding-left: 8px;
    padding-right: 8px;
}
.top-page-notification ~ .container .full-page-section {
    height: calc(100vh - 162px);
}
.full-page-section > *{
    margin-left: 8px;
    margin-right: 8px;
}
.full-page-section .aside--controls-1{
    width: calc(395px + 16px);
    flex: 0 0 calc(395px + 16px);
}
.full-page-section .aside--controls-2{
    width: calc(282px + 16px);
    flex: 0 0 calc(282px + 16px);
}
.aside--controls-2{
    position: relative;
}
.aside--controls-2 .aside-header{
    position: absolute;
    width: 100%;
}
.tooltip{
    display: flex;
    flex-direction: column;
}
.tooltip-btn{
    color: var(--primary);
}

::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar-track {
    background: #14141A; /* цвет зоны отслеживания */
}
  
::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.04); 
    border-radius: 20px;    
    border: 5px solid #14141A; 
}



.select-decor{
    border: 1px solid transparent;
    transition: all 0.5s;
}
a.select-decor:hover{
    border-color: var(--primary);
}
@keyframes shine {
    to {
      background-position-x: -200%;
    }
}
.skeleton{
    background: linear-gradient(110deg, rgba(255, 255, 255, 0.05) 8%, rgba(255, 255, 255, 0.04) 18%, rgba(255, 255, 255, 0.04) 33%);
    border-radius: 10px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    width: 100%;

}
.--skeleton-text{
    height: 12px;
}
.--skeleton-card-lg{
    height: 268px;
}
.--skeleton-card-md{
    height: 120px;
}
.--skeleton-card-md-2{
    height: 166px;
}
.--skeleton-input{
    height: 44px;
}


.loader {
    font-size: 10px;
    margin: 0px auto;
    text-indent: -9999em;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    background: var(--primary);
    background: -moz-linear-gradient(left, var(--primary) 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, var(--primary) 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, var(--primary) 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, var(--primary) 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, var(--primary) 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: var(--primary);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #14141A;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .disabled-2, .disabled-2 *{
    pointer-events: none;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

.card-check{
    display: flex;
    cursor: pointer;
    flex-direction: column;
}
.card-check input:checked ~ .card-photo{
    border-color: var(--primary);
}
.card-check input:checked ~ .card-photo .ico-success{
    opacity: 1;
}
.card-check input{
    display: none;
}
.card-photo{
    max-height: 166px;
    height: 166px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    border: 1px solid transparent;
}
.card-photo .card-wrapper-info{
    bottom: 0;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
}
.card-photo .ico-success{
    position: absolute;
    top: 0;
    margin: 10px;
    opacity: 0;
}
.card-photo .card-photo-info{
    border-radius: 300px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    color: var(--white);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    width: max-content;
    max-width: 100%;

    padding: 2px 8px;
    text-align: center;
}
.card-photo img{
    width: 100%;
    height: 100%;
}
.latest-render-exzample .render-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
}
.user-avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
}
.avatar-placeholder {
    min-width: 36px;
    min-height: 36px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-placeholder:not(.skeleton) {
    background: #28282E;
}
.user-avatar img,
.setting__user-img img {
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
    height: 100%;
    max-width: inherit;
}
#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.close-modal-btn {
    position: absolute;
    right: 16px;
    top: 16px;
}
.close-modal-btn svg g {
    transition: 0.15s all ease-in-out;
}
.close-modal-btn:hover svg g {
    opacity: 1;
}
.btn.hidden {
    display: none;
}
.remove-card-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    top: 13px;
    opacity: 0.2;
}
.remove-card-btn:hover {
    opacity: 1;
    background: #28282E;
}
.remove-render-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(23, 22, 29, 0.40);
}
.remove-render-btn svg {
    opacity: 0.7;
    transition: 0.3s all ease-in-out;
    max-width: 16px;
}
.remove-render-btn:hover svg {
    opacity: 1;
}
.select-card-loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
}
.full-page-section .latest-render-label,
.full-page-section .latest-render-name,
.full-page-section .modal__content {
    -webkit-backdrop-filter: none !important;
    backdrop-filter: none !important;
}
.full-page-section .modal__content {
    background: rgba(20, 20, 26, 0.90);
}
.render-results-slider.swiper .swiper {
    overflow: hidden;
}
.render-results-slider .swiper .swiper-slide {
    height: initial;
}
.render-results-slider .swiper .slider-label {
    background: rgba(104, 101, 121, 0.4);
}
.render-results-slider .thumbs-render-swiper .swiper-slide img {
    width: initial;
    margin: 0 auto;
    display: block;
}
.modal__dialog.modal__dialog--650 {
    max-width: 650px;
}
.transform-rotate-180 {
    transform: rotate(180deg);
}
.language-list {
    position: absolute; 
    right: 12px;
    top: 100%; 
    z-index: 2; 
    background: #28282E; 
    min-width: 150px;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 230px;
}
.language-list li+li {
    margin-top: 3px;
}
.language-list li a {
    padding: 3px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    white-space: nowrap;
}
.language-list li a:hover,
.language-list li a.active {
    color: #9EE22E;
}
.render-results-slider .swiper .slider-images .background-img .after-badge,
.render-results-slider .swiper .slider-images .foreground-img .before-badge {
    border-radius: 300px;
    background: rgba(104, 101, 121, 0.4);
    color: rgba(255, 255, 255, .8);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 7px;
}