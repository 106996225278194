@media (max-width: 1199.98px) {
    .full-page-section .aside--controls-1{
        width: calc(300px + 16px);
        flex: 0 0 calc(300px + 16px);
    }
}

@media (max-width: 991.98px) {
    .aside--controls-1.hide-aside{
        left: -100% !important; 
    }
    .aside--controls-2.show-aside{
        left: 0% !important; 
    }
    .aside--controls-1.show-aside{
        left: 0% !important; 
    }
    .header-fixed .content{
        height: calc(100% - var(--header-fixed-padding));
        /* display: flex; */
    }
    .header-fixed .content.landing-content {
        flex-direction: column;
    }
    .slider-images {
        max-width: 700px;
        margin: 0 auto;
    }
    .full-page-section{
        height: 100%;
    }
    .close-mobile{display:flex !important;}
    .full-page-section .aside--controls-1{
        position: fixed;
        height: calc(100% - calc(81px + 16px));
        width: 100%;
        flex: 0 0 100%;
        z-index: 2;
        left: 0;
        margin: 0;
    }

    .full-page-section .aside--controls-2{
        position: fixed;
        height: calc(100% - calc(81px + 16px));
        width: 100%;
        flex: 0 0 100%;
        z-index: 1;
        left: 100%;
        margin: 0;
    }
    
    /* .btn--render-js-2{
        display: flex !important;
    } */
    /* .btn--render-js-1{
        display: none !important;
    } */

    .full-page-section__content.page-generator-have-content {
        min-height: calc(100dvh - 99px);
        position: relative;
        z-index: 2;
        transition: 0.3s all ease-in-out;
    }
    .full-page-section__content.page-generator-have-content.to-close {
        transform: translateX(100%);
    }

    .full-page-section.page-generator-have-content.to-close .aside--controls-1 {
        transform: translateX(-100%);
        overflow: initial;
    }

    .full-page-section .aside--controls-2.active {
        z-index: 2;
        left: 0;
    }
    .section__content-sidebar-burger {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section__content-sidebar-burger-2 {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        right: -36px;
        top: 20px;
        z-index: 99999;
        background: #14141A;
        border-radius: 0 4px 4px 0;
    }
    .full-page-section.page-generator-have-content.to-close .section__content-sidebar-burger-1 {
        opacity: 0;
        visibility: hidden;
    }
    .full-page-section.page-generator-have-content.to-close .section__content-sidebar-burger-2 {
        opacity: 1;
        visibility: visible;
    }
    .xs\:flex-col {
        flex-direction: column;
    }
    .mob-pr-for-burger {
        padding-right: 34px;
    }
    .top-page-notification {
        position: absolute;
        z-index: 3;
    }
    .page-generator-have-content:not(.to-close) .full-page-section__content {
        /* opacity: 0; */
    }
    .language-list {
        position: initial;
    }
}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {
    .footer-top-actions {
        flex-direction: column;
    }
    .footer-top-actions p,
    .footer-top-actions a {
        margin: 0;
    }
    .footer-top-actions a {
        margin-top: 12px;
    }
    .section {
        padding: 40px 0;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
    .btn--render-js-2{
        display: none !important;
    }
    .close-mobile{
        display:none !important;
    }
    .section__content-sidebar-burger {
        display: none;
    }
}

@media (min-width: 1200px) {

}
