.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    padding-top: 16px;
    /* background: #101014; */
}
.header-short .container {
    max-width: 930px;
}
.header__link-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.header__body {
    border-radius: 360px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.10);
    background: rgba(16, 16, 20, 0.98);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 14px 14px 20px;
}
.header-logo {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.333px;
}
.header-logo__text {
    margin-left: 13px;
}
.header__menu {
    display: flex;
    align-items: center;
}
.header__list {
    display: flex;
    align-items: center;
}
.header__list li {
    margin-left: 10px;
}
.header-nav {
    display: flex;
    align-items: center;
}
.header__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 15px;
}
.header__link.active,
.header__link:hover {
    color: var(--primary);
}
@supports (backdrop-filter: blur(30px)) {
    .header__body {
        background: rgba(16, 16, 20, 0.60);
        backdrop-filter: blur(30px);  
    }
}
.header__burger {
    display: none;
    overflow: hidden;
}

.header__menu.active {
   display: block;
   animation: menuAnim .2s linear forwards;
}
@keyframes menuAnim {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 1200px) {
    .header__burger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 5;
    }

    .header__burger:before,
    .header__burger:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    .header__burger:before {
        top: 0;
    }

    .header__burger:after {
        bottom: 0;
    }

    .header__burger.active:before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .header__burger.active span {
        -webkit-transform: translateX(-35px);
        -ms-transform: translateX(-35px);
        transform: translateX(-35px);
    }

    .header__burger span {
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 9px;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    .header__menu {
        position: absolute;
        top: 100%;
        display: none;
        right: 0;
        background: #101014;
        overflow: auto;
    }
    .header__list {
        flex-direction: column;
        padding: 5px 20px;
    }
    .header__list li {
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }
}