.skeleton-show{
    display: none;
}
.skeleton-active .skeleton-show{
    display: block;
}
.skeleton-active .skeleton-hide{
    display: none;
}
.input-custom__icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}
.input-custom__wrap-input .input-custom__icon+.input-custom__input{
    padding-left: 36px;
}
.select-popup{
    max-height: calc( 100vh - 32px - 90px - 74px - 33px );
    padding: 0;
}
.select-popup .input-custom{
    padding: 0 36px;
}
.select-popup .select-popup__wrapper{
    overflow-y: auto;
    
    overflow-x: hidden;
    width: 100%;
    padding: 0 36px;
}
.select-popup .select-popup__wrapper .row{
    transform: translateX(8px);
    margin-right: calc(var(--bs-gutter-x) * -1);
    margin-left: calc(var(--bs-gutter-x) * -1);
    --bs-gutter-y: inherit;
}
.render-img-fix{
    width: 240px;
    height: 170px;
    border-radius: 10px;
}
.qr-code-wrap img{
    width: 150px;
    height: 150px;
}
.no-renders-popup{
    padding: 0 0 30px;
    text-align: center;
}
.no-renders-popup-text--margins  {
    margin-bottom: 32px;
}
.no-renders-popup-text + .no-renders-popup-text  {
    margin-top: 10px;
    margin-bottom: 32px;
}
.no-renders-popup-title{
    text-align: center;
}
.mt-10{
    margin-top: 10px;
}
.fw-600{
    font-weight: 600;
}
.modal__close{
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
}
.latest-render-exzample{
    cursor: pointer;
}
.copy-btn {
    position: relative;
    min-height: 44px;
    min-width: 44px;
}

.test-icon {
    position: absolute;
    top: -2px;
    left: -2px;
}

.test-icon .icon-path {
    stroke-dashoffset: 100;
    transition: all 700ms linear;
}
  
.animated .test-icon .icon-path {
    stroke-dashoffset:0;
}

.upload-wrap__delete{
    position: absolute;
    top: 5px;
    right: 5px;
}
.upload-wrap__delete #Delete{
    fill: rgba(255, 255, 255, 0.6);
}
.img-portrait-wrpr {
    background: #28282E;
}
.latest-render-exzample .render-img.img-portrait { 
    max-height: 100%;
    width: initial;
    margin: 0 auto;
    /* 28282E */
}
.latest-render-exzample {
    background: #28282E;
}
.render-results-slider .thumbs-render-swiper .swiper-slide img {
    max-height: 100px !important;
    min-height: initial !important;
}
.swiper.render-results-slider .fullpage-actions {
    position: absolute;
    bottom: 32px;
    left: 20px;
    z-index: 2;
}
.latest-renders-page .swiper.render-results-slider .fullpage-actions {
    left: 50%;
    transform: translateX(-50%);
}
.thumbs-render-swiper .show-only-big-slider {
    display: none !important;
}
.main-render-swiper .show-only-thumb-slider {
    display: none !important;
}
.scaling-please-wait {
    padding: 8px 14px;
    border-radius: 12px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    max-width: 108px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;
}
.scaling-please-wait h3 {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
.scaling-please-wait span {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.6;
    display: block;
}
.upscale-blur-loading {
    filter: blur(2px);
}
:root {
    --plyr-color-main: #9EE22E !important;
}
.blur-btn {
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
}

.lds-spinner {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    scale: 0.4;
}
.lds-spinner div {
    transform-origin: 20px 20px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 2px;
    left: 20px;
    width: 2px;
    height: 8px;
    border-radius: 20%;
    background: #fff;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}