.text-white--gradient {
    background: -webkit-linear-gradient(0deg, #FFFFFF 0%, #999999 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container-830 {
    max-width: calc(830px + 30px);
}

.container-1024 {
    max-width: calc(1024px + 30px);
}

.swiper.swiper-hero {
    /* height: 530px; */
    aspect-ratio: 830 / 530;
    width: 100%;
    overflow: visible;
}

.swiper-hero .swiper-slide {
    overflow: hidden;
    border-radius: 16px;
}

.swiper-hero .swiper-slide img {
    width: 100%;
    height: 100%;
}

.btn--xl {
    min-height: 56px;
}

.slider-images-home .slider-images {
    height: 310px;
}

.slider-images-home .slider-images .img {
    background-size: 500px 100%;
}

.slider-images-home .slider-images-home--full .slider-images {
    height: 576px;
}

.slider-images-home .slider-images-home--full .slider-images .img {
    background-size: 1024px 100%;
}

.header__dropdown-body {
    min-width: 174px;
    padding: 4px;
    flex-direction: column;
    gap: 4px;
    border-radius: 10px;
    background-color: var(--secondary-2);
}

.header__dropdown-button {
    display: flex;
    align-items: center;
    gap: 8px
}

.dropdown__body.header__dropdown-body.is-open {
    display: flex;
}

.header__dropdown-button .ico {
    transform: rotate(180deg);
}

.header__dropdown.dropdown-open .header__dropdown-button {
    color: var(--primary);
}

.header__dropdown.dropdown-open .header__dropdown-button .ico {
    transform: rotate(0);
}

.header__dropdown-body .btn {
    justify-content: flex-start;
    gap: 12px;
    min-height: 40px;
    padding-left: 14px;
}

.btn-shapes.btn-shapes--xl .border-efects {
    /* width: 250px; */
    height: 56px;
}

.btn-shapes.btn-shapes--left.btn-shapes .shadow-efect-1,
.btn-shapes.btn-shapes--left.btn-shapes .shadow-efect-2,
.btn-shapes.btn-shapes--left.btn-shapes .border-efects {
    transform: translate(0%, -50%);
    left: 0;
}

.btn-shapes.btn-shapes--left {
    justify-content: flex-start;
}

.btn-shapes {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.btn-shapes .border-efects {
    width: 100%;
}

.features-item.features-item--row {
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 24px;
}

.features-item.features-item--row .features-item__icon {
    margin-bottom: 0;
}

.features-item.features-item--row .features-item__text {
    margin-top: 0;
}

.features-item__icon.features-item__icon--md {
    width: 56px;
    height: 56px;
    min-width: 56px;
}

.aside-footer .btn {
    flex-direction: row;
    gap: 8px;
}

.aside-header {
    padding-left: 16px;
    padding-right: 16px;
}
/* .render-results-slider .swiper-slide img {
    width: auto;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
} */
.widthout-image-text.slider-images .background-img .after-badge,
.widthout-image-text.slider-images .foreground-img .before-badge {
    display: none;
}
.react-tooltip {
    z-index: 9;
    font-size: 14px !important;
}

.aside-alert {
    padding: 8px 16px;
    font-size: 14px;
    background-color: var(--danger);
    color: var(--white);
    border-radius: 10px;
}

@media (max-width: 991.98px) {
    .slider-images-home .slider-images-home--full .slider-images {
        height: 310px;
    }

    .slider-images-home .slider-images-home--full .slider-images .img {
        background-size: 576px 100%;
    }
    .slider-images-home .slider-images {
        height: 310px!important;
    }
}