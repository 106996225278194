.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 300;
	background: rgba(0, 0, 0, 0.8);
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition: all 0.15s linear;
    overflow-x: hidden;
}

.modal--show { 
	opacity: 1;
	visibility: visible;
}

.modal--show .modal__dialog {
	transform: none;
}

.modal__dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	margin: auto;
	padding: 16px;
	transform: translate(0, -20px);
	transition: transform .3s ease-out;
	height: 100%;
}

.modal__dialog--460 {max-width: 500px;}
.modal__dialog--500 {max-width: 540px;}
.modal__dialog--572 {max-width: 612px;}
.modal__dialog--800 {max-width: 840px;}
.modal__dialog--1000 {max-width: 1000px;}


.modal__content {
    display: flex;
	flex-direction: column;
	width: 100%;
	background: rgba(20, 20, 26, 0.50);
	backdrop-filter: blur(30px);
	border-radius: 16px;
	transition: all 0.3s ease-out;
	position: relative;
	
}

.modal__body {
	padding: 0 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.modal__header {
    padding: 30px 36px 0;
    display: flex;
	flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.modal__footer {
	padding: 0 36px 30px;
    display: flex;
    align-items: center;
}

.modal__title{
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
}