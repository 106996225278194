.switch-black .switch__slider{
    background: #28282E;
}

.aside--controls-3 {
    width: calc(180px + 16px);
    flex: 0 0 calc(180px + 16px);
}

.card-wrapper-info.position-center{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
}

.aside--controls-3 .card-photo{
    max-height: 100px;
    height: 100px;
}

.message-block-center{
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: rgba(23, 22, 29, 0.40);
    backdrop-filter: blur(20px);
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 14px;
}

.slider-line.slider-line-black{
    background: #101014;
}

.info-block-left-top{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.card-wrapper-info__right-top{
    bottom: auto;
    position: absolute;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    right: 0;
    top: 0;
}

.img-decor-20x20{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px
}

.swiper-slide__img{
    border-radius: 16px;
    overflow: hidden;
}

.swiper.explore-real{
    overflow: visible;
}

.fs-12{
    font-size: 12px;
}

.slider-images-576-mobile .slider-images{
    height: 576px;
}





.btn--render-js-1,
.btn--render-js-2{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.render-list-sidebar{
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    line-height: 16px;
    color: #A1A1A3;
    margin-left: -8px;
    margin-right: -8px;
    gap: 16px 0;
    overflow: hidden;
    white-space: nowrap;
}
.info-render-item{
    display: flex;
    gap: 3px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}
.render-count{
    display: flex;
    align-items: center;
    gap: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.render-count p{
    overflow: hidden;
    text-overflow: ellipsis
}
.render-list-sidebar > li{
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
    max-width: 50%;
    overflow: hidden;
}

.pricing {
    max-width: 954px;
}
.before-after-thumbnail {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    display: flex;
    align-items: center;
}
.before-after-thumbnail li {
    display: block;
    margin: 0 5px;
}
.before-after-thumbnail li a {
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    overflow: hidden;
    height: 74px;
    min-height: 74px;
    border: 2px solid #FFFFFF99;
}
.before-after-thumbnail li a.active {
    border: 2px solid #9EE22E;
}
.before-after-thumbnail li a img {
    min-height: 100%;
    min-width: 100%;
}

@media (max-width: 991.98px) {
    .slider-images-576-mobile .slider-images{
        height: 310px;
    }
    
}