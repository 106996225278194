@media (max-width: 1199.98px) {

}

@media (max-width: 991.98px) {
    .pricing-page{
        flex-direction: column;
    }
}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {
    .qr-code-wrap{
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        margin-top: 30px;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
