:root {
    --defaultTextColor: #fff;
    --white: #fff;
    --primary: #9EE22E;
    --secondary: #D3D3D3;
    --secondary-2: #28282E;
    --warning: yellow;
    --danger: red;
    /* tipografy */
    --fotnFamily: 'SF Pro Text', sans-serif;
    --defaultFontSize: 18px;
    --defaultLineHeight: 24px;
    --h1Size: 48px;
    --h2Size: 40px;
    --h3Size: 30px;
    --h4Size: 20px;
    --h5Size: 10px;
    --defaultRaduis: 5px;
    --header-fixed-padding: 81px;
}

body {
    font-size: 100%;
    font-weight: normal;
    font-family: var(--fotnFamily);
    font-weight: 400;
    font-size: var(--defaultFontSize);
    line-height: var(--defaultLineHeight);
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: var(--defaultTextColor);
    background-color: #101014;
}

body.lock {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: bold;
    font-family: var(--fotnFamily);
    line-height: 120%;
}

h1 {
    font-size: var(--h1Size);
}

h2 {
    font-size: var(--h2Size);
}

h3 {
    font-size: var(--h3Size);
}

h4 {
    font-size: var(--h4Size);
}

h5 {
    font-size: var(--h5Size);
}

.input,
.textarea {
    padding: 10px 0;
    font-size:  var(--defaultFontSize);
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #BABDDB;
}
input,
button,
textarea {
    font-family: var(--fotnFamily);
}

.radius-5 {
    border-radius: var(--defaultRaduis);
}
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}
.header-fixed {
    padding-top: var(--header-fixed-padding);
}
.content {
    flex: auto;
}
.container {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
}

/* colors */

.color-primary {
    color: var(--primary);
}
.color-danger {
    color: var(--danger);
}
.color-warning {
    color: var(--warning);
}
.color-secondary {
    color: var(--secondary);
}

/* bg-colors */

.bg-primary {
    background-color: var(--primary);
}
.bg-danger {
    background-color: var(--danger);
}
.bg-warning {
    background-color: var(--warning);
}
.bg-secondary {
    background-color: var(--secondary);
}